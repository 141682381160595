import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.jpg";
import { PhoneIcon } from "./icons";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar-item call-button-main">
          <a
            href="tel:18085237769"
            className="btn is-flex is-flex-direction-row is-align-items-flex-end is-justify-content-center"
          >
            <PhoneIcon className="mr-2" />
            <p>Call one of our ProZ! (808) 523-7769</p>
          </a>
        </div>
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img
                src={logo}
                alt="Security Proz Hawaii"
                style={{ width: "auto", maxHeight: "100px", paddingBottom: "2px" }}
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/services">
                Services
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact Us
              </Link>
              <div className="navbar-item call-button-mobile">
                <a
                  href="tel:18085237769"
                  className="btn is-flex is-flex-direction-row is-align-items-flex-end is-justify-content-center"
                >
                  <PhoneIcon className="mr-2" />
                  <p>Call Us Now</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
